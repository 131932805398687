import { useEffect } from 'react';
import { ResponseError } from 'services/api/base';
import { useLazyGetInstructionPdfQuery } from 'services/api/products/flux';
import { useHandleRequestErrors } from 'utils/hooks';

const pdfUrls = {
  1: 'https://site-exclusivo.s3.us-east-1.amazonaws.com/flux/Geral.pdf',
  2: 'https://site-exclusivo.s3.us-east-1.amazonaws.com/flux/EstudosAutomaticos.pdf',
};

export default function useInstructionPdf(id: number, triggerRequest: boolean) {
  const [trigger, { data, error, isFetching }] = useLazyGetInstructionPdfQuery();

  useEffect(() => {
    if (triggerRequest && data === undefined) {
      trigger({ id });
    }
  }, [triggerRequest, trigger, id, data]);

  useHandleRequestErrors(error as ResponseError);

  return {
    base64Pdf: data,
    loading: isFetching,
    pdfUrl: pdfUrls[id],
  };
}
