import React from 'react';
import { Col } from 'react-bootstrap';

import CentralizedSpinner from 'components/CentralizedSpinner';
import useAreaReference from 'hooks/Meteorology/Weather/Area/References/useAreaReferences';

import './index.scss';

export default function References({
  areaAcronym,
  areaLabel,
}: {
  areaAcronym: string;
  areaLabel: string;
}) {
  const { pdfUrl, isLoading } = useAreaReference({ area: areaAcronym });

  if (isLoading) {
    return <CentralizedSpinner />;
  }

  return (
    <Col className="p-4">
      <object
        title={`${areaLabel} reference`}
        data={pdfUrl}
        className="weather-reference-pdf"
      />
    </Col>
  );
}
