import React from 'react';
import useAreaHistory from 'hooks/Meteorology/Weather/Area/History/useAreaHistory';
import { WeatherAreaHistory } from 'types/Meteorology/Weather/Area/getHistoryResponse';
import AlertsCollection from 'components/AlertsCollection';
import CentralizedSpinner from 'components/CentralizedSpinner';
import HistoryCard from '../HistoryCard';

import './index.scss';

export default function RegularHistory({
  areaAcronym,
  areaLabel,
}: {
  areaAcronym: string,
  areaLabel: string,
}) {
  const { history, isLoading, error } = useAreaHistory({ area: areaAcronym });
  const weatherHistory = history as WeatherAreaHistory | undefined;

  if (isLoading) {
    return <CentralizedSpinner />;
  }

  if (error) {
    return (
      <div className="mt-4">
        <AlertsCollection
          alerts={[{ title: '', message: 'Dados indisponíveis', variant: 'warning' }]}
        />
      </div>
    );
  }

  return (
    <HistoryCard
      areaAcronym={areaAcronym}
      areaLabel={areaLabel}
      valueData={weatherHistory?.valueData || []}
      categoryData={weatherHistory?.categoryData || []}
    />
  );
}
