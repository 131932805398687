import React from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import AlertsCollection from 'components/AlertsCollection';
import CentralizedSpinner from 'components/CentralizedSpinner';
import EnlargeableImage from 'components/Meteorology/Fields/SynopticCharts/SynopticChart/Map/EnlargeableImage';
import mapAnnotations from 'helper/Meteorology/Weather/Overview/AnomalyPanel/mapAnnotations';

import './index.scss';

export default function AnomalyPanel({
  isFetching,
  image,
  error,
  period,
  handlePeriodChange,
  periodOptions,
}: {
  isFetching: boolean,
  image: string,
  error: boolean,
  period: string,
  handlePeriodChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  periodOptions: { value: string; label: string; }[],
}) {
  if (isFetching) {
    return <CentralizedSpinner />;
  }

  return (
    <>
      <Row className="mx-3">
        <Col md={8} className="anomaly-title">
          Anomalia da TSM
        </Col>
        <Col md={4}>
          <Form.Select
            id="periodSelect"
            value={period}
            onChange={handlePeriodChange}
          >
            {periodOptions.map((option) => (
              <option value={option.value} key={option.value}>{option.label}</option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      {error || !image ? (
        <div className="mt-4">
          <AlertsCollection
            alerts={[{ title: '', message: 'Imagem indisponível', variant: 'warning' }]}
          />
        </div>
      ) : (
        <>
          <div className="anomaly-map">
            <EnlargeableImage src={`data:image/png;base64,${image}`} className="mt-3" />
            <div className="map-tropics" />
            {mapAnnotations.map((annotation) => (
              <Link
                id={`map-annotation-${annotation.url}`}
                to={`/meteorology/weather/${annotation.url}`}
                className="map-annotation"
                key={annotation.acronym}
                style={{ left: `${annotation.left}%`, top: `${annotation.top}%` }}
              >
                {annotation.acronym}
              </Link>
            ))}
          </div>
          <Row className="mx-4 mt-3">
            {mapAnnotations.map((legend) => (
              <Col key={legend.acronym} md={6} lg={4} className="text-start my-2">
                <Link
                  id={`legend-${legend.url}`}
                  to={`/meteorology/weather/${legend.url}`}
                  className="map-legend-label"
                >
                  {`${legend.acronym} - ${legend.label}`}
                </Link>
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  );
}
