import { useEffect } from 'react';

import { useLazyGetClassicDataQuery } from 'services/api/products/flux';
import useClassicOptions from 'hooks/Flux/Automatic/Classic/useClassicOptions';
import useClassicRevisionList from './useClassicRevisionList';

export default function useClassicTableData() {
  const { acomphDateLabel, forecastDateLabel, model } = useClassicOptions();
  const { selectedRevision: revision } = useClassicRevisionList();
  const [trigger, { data, isFetching, error }] = useLazyGetClassicDataQuery();

  const paramsAvailable = acomphDateLabel && forecastDateLabel && model && revision;
  const modelData = paramsAvailable && !error ? data : null;

  useEffect(() => {
    if (acomphDateLabel && forecastDateLabel && model && revision) {
      trigger({
        acomphDateLabel,
        forecastDateLabel,
        model,
        revision,
      });
    }
  }, [acomphDateLabel, forecastDateLabel, model, revision, trigger]);

  return {
    modelData,
    isFetching,
    revision,
    model,
    forecastDateLabel,
    acomphDateLabel,
  };
}
