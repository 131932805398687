import React from 'react';
import {
  Card,
  Button,
  FormSelect,
} from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAreaPredictionImage from 'hooks/Meteorology/Weather/Area/Status/useAreaPredictionImage';
import EnlargeableImage from 'components/Meteorology/Fields/SynopticCharts/SynopticChart/Map/EnlargeableImage';
import usePredictionImageDownload from 'hooks/Meteorology/Weather/Area/Status/usePredictionImageDownload';
import CentralizedSpinner from 'components/CentralizedSpinner';
import useModelOptions from 'hooks/Meteorology/Weather/Area/Status/useModelOptions';
import usePeriodOptions from 'hooks/Meteorology/Weather/Area/Status/usePeriodOptions';
import usePredictionParametersSelection from 'hooks/Meteorology/Weather/Area/Status/usePredictionParametersSelection';
import usePluma from 'hooks/Meteorology/Weather/Area/Status/usePluma';
import { useClosure } from 'utils/hooks';
import IndexPredictionModal from './IndexPredictionModal';

export default function PreviewPanel({ area }: { area: string }) {
  const [showIndexPrediction, setShowIndexPrediction] = React.useState(false);
  const onIndexPredictionClick = useClosure(setShowIndexPrediction, true);
  const {
    modelOptions,
    modelOptionsLoading,
  } = useModelOptions();
  const {
    periodOptions,
    periodOptionsLoading,
    triggerPeriods,
    setPeriodOptions,
  } = usePeriodOptions();
  const {
    predictionImageBase64,
    predictionImageLoading,
    setImage,
    triggerPrediction,
  } = useAreaPredictionImage();
  const {
    model,
    period,
    onModelChange,
    onPeriodChange,
  } = usePredictionParametersSelection({
    area,
    setPeriodOptions,
    setImage,
    triggerPeriods,
    triggerPrediction,
  });
  const { onPredictionDownloadClick, downloadDisabled } = usePredictionImageDownload(
    predictionImageBase64,
    predictionImageLoading,
    area,
    model,
    period,
  );
  const {
    data: indexPredictionData,
    isLoading: indexPredictionLoading,
    error: plumaError,
  } = usePluma(area);

  return (
    <>
      <IndexPredictionModal
        area={area}
        show={showIndexPrediction}
        onHide={useClosure(setShowIndexPrediction, false)}
        indexPredictionData={indexPredictionData}
      />
      <Card className="shadow-sm forecast-instruction-card h-100">
        <Card.Header>
          PREVISÃO ESPACIAL
        </Card.Header>
        <Card.Body>
          {modelOptionsLoading || periodOptionsLoading ? (
            <CentralizedSpinner />
          ) : (
            <>
              <div className="d-lg-flex mt-3 mx-3 align-items-center">
                <FormSelect
                  id="modelSelect"
                  className="me-lg-2"
                  value={model}
                  onChange={onModelChange}
                >
                  <option value="">Modelo</option>
                  {modelOptions.map((option) => (
                    <option value={option} key={option}>{option}</option>
                  ))}
                </FormSelect>
                <FormSelect
                  id="predictionPeriodSelect"
                  className="ms-lg-2"
                  value={period}
                  onChange={onPeriodChange}
                >
                  <option value="">Período</option>
                  {periodOptions.map((option) => (
                    <option value={option} key={option}>{option}</option>
                  ))}
                </FormSelect>
                <Button
                  id="previewImageDownloadButton"
                  size="sm"
                  className="icon-button weather-download-button ms-lg-3"
                  onClick={onPredictionDownloadClick}
                  disabled={downloadDisabled}
                >
                  <FontAwesomeIcon icon={faDownload as IconProp} />
                </Button>
                <Button
                  id="indexPredictionButton"
                  size="sm"
                  className="icon-button weather-download-button ms-lg-3"
                  onClick={onIndexPredictionClick}
                  disabled={indexPredictionLoading || !!plumaError}
                  title="PREVISÃO do ÍNDICE"
                >
                  <FontAwesomeIcon icon={faList as IconProp} />
                </Button>
              </div>
              {predictionImageLoading && <CentralizedSpinner />}
              {!predictionImageLoading && predictionImageBase64 && (
                <EnlargeableImage src={`data:image/png;base64,${predictionImageBase64}`} className="mt-3" />
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
