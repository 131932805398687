import React from 'react';
import useInstructionPdf from 'hooks/Flux/About/useInstructionPdf';
import { InstructionTab } from 'types/Flux/About/getInstructionTabsResponse';

export default function AboutSection({ tab, activeKey }: {
  tab: InstructionTab,
  activeKey: string | null,
}) {
  const { pdfUrl } = useInstructionPdf(tab.id, tab.id === Number(activeKey));

  return (
    <div className="p-4">
      {(tab.id === Number(activeKey)) && (
        <object
          title={`${tab.title} PDF`}
          data={pdfUrl}
          className="flux-about-pdf"
        />
      )}
    </div>
  );
}
