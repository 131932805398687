import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LabeledScenarios, SpacialSubdivisionOption, SpatialSubdivision, SpatialSubdivisionWithOption,
} from 'helper/flux/automatic';
import { AutomaticPreference } from 'helper/products/flux';

export const possibleMltOptions = ['%mlt', 'mwmed'] as const;
export type MltOptions = typeof possibleMltOptions[number];

interface AutomaticControlPanelState {
  spatialSubdivision: SpatialSubdivisionWithOption | undefined,
  automaticScenarios: string[],
  automaticStudy: LabeledScenarios[],
  acomph: boolean,
  mlt: boolean,
  pastSimulations: number[],
  mltOption: MltOptions;
  selectedRevision: string | undefined;
  classicAcomphDate: string,
  classicForecastDate: string,
  classicModel: string,
  classicRevision: string,
}

const initialState: AutomaticControlPanelState = {
  spatialSubdivision: undefined,
  automaticScenarios: [],
  automaticStudy: [],
  acomph: true,
  mlt: true,
  pastSimulations: [],
  mltOption: '%mlt',
  selectedRevision: undefined,
  classicAcomphDate: '',
  classicForecastDate: '',
  classicModel: '',
  classicRevision: '',
};

const toggleFromList = <T>(list: T[], value: T) => {
  const foundIndex = list.findIndex(
    (item) => item === value,
  );

  if (foundIndex >= 0) {
    return list.filter(
      (item) => item !== value,
    );
  }

  list.push(value);

  return list;
};

export const fluxAutomaticControlPanelSlice = createSlice({
  name: 'fluxAutomaticControlPanel',
  initialState,
  reducers: {
    setSpatialSubdivision: (
      state,
      action: PayloadAction<[subdivision: SpatialSubdivision,
        spacialSubdivisionOption: SpacialSubdivisionOption]>,
    ) => {
      const [subdivision, spacialSubdivisionOption] = action.payload;
      state.spatialSubdivision = {
        spatialSubdivision: subdivision,
        spacialSubdivisionOption,
      };
    },
    clearSpatialSubdivision: (state) => {
      state.spatialSubdivision = undefined;
    },
    setAutomaticStudyPreferenceScenarios: (state, action: PayloadAction<AutomaticPreference>) => {
      state.automaticScenarios = action.payload.scenarios;
    },
    setAutomaticStudyScenarios: (state, action: PayloadAction<LabeledScenarios | undefined>) => {
      state.automaticScenarios = action.payload?.scenarios.map(
        (scenario) => scenario.scenario,
      ) ?? [];
    },
    clearAutomaticStudyScenarios: (state) => {
      state.automaticScenarios = [];
      state.pastSimulations = [];
    },
    toggleAutomaticStudy: (state, action: PayloadAction<string>) => {
      state.automaticScenarios = toggleFromList(state.automaticScenarios, action.payload);
    },
    setAutomaticStudy: (state, action: PayloadAction<LabeledScenarios[]>) => {
      state.automaticStudy = action.payload;
    },
    setAcomph: (state, action: PayloadAction<boolean>) => {
      state.acomph = action.payload;
    },
    setMLT: (state, action: PayloadAction<boolean>) => {
      state.mlt = action.payload;
    },
    togglePastSimulation: (state, action: PayloadAction<number>) => {
      state.pastSimulations = toggleFromList(state.pastSimulations, action.payload);
    },
    setPastSimulations: (state, action: PayloadAction<AutomaticPreference>) => {
      state.pastSimulations = action.payload.pastSimulations;
    },
    setMltOption: (state, action: PayloadAction<MltOptions>) => {
      state.mltOption = action.payload;
    },
    setRevisionDate: (state, action: PayloadAction<string>) => {
      state.selectedRevision = action.payload;
    },
    setClassicAcomphDate: (state, action: PayloadAction<string>) => {
      state.classicAcomphDate = action.payload;
    },
    setClassicForecastDate: (state, action: PayloadAction<string>) => {
      state.classicForecastDate = action.payload;
    },
    setClassicModel: (state, action: PayloadAction<string>) => {
      state.classicModel = action.payload;
    },
    setClassicRevision: (state, action: PayloadAction<string>) => {
      state.classicRevision = action.payload;
    },
    reset: () => initialState,
  },
});

export const selectFluxAutomaticControlPanelSpatialSubdivision = (
  state: any,
): SpatialSubdivisionWithOption | undefined => (
  state.fluxAutomaticControlPanel.spatialSubdivision
);
export const selectFluxAutomaticControlPanelAutomaticStudyScenarios = (
  state: any,
): string[] => (
  state.fluxAutomaticControlPanel.automaticScenarios
);

export const selectFluxAutomaticControlPanelAutomaticStudy = (state: any): LabeledScenarios[] => (
  state.fluxAutomaticControlPanel.automaticStudy
);

export const selectFluxAutomaticControlPanelAcomph = (state: any): boolean => (
  state.fluxAutomaticControlPanel.acomph
);

export const selectFluxAutomaticControlPanelMLT = (state: any): boolean => (
  state.fluxAutomaticControlPanel.mlt
);

export const selectFluxAutomaticControlPanelPastSimulations = (state: any): number[] => (
  state.fluxAutomaticControlPanel.pastSimulations
);

export const selectFluxAutomaticControlPanelMltOption = (state: any): MltOptions => (
  state.fluxAutomaticControlPanel.mltOption
);

export const selectFluxAutomaticControlPanelSelectedRevision = (state: any): string | undefined => (
  state.fluxAutomaticControlPanel.selectedRevision
);

export const selectFluxAutomaticControlPanelClassicAcomphDate = (state: any): string => (
  state.fluxAutomaticControlPanel.classicAcomphDate
);

export const selectFluxAutomaticControlPanelClassicForecastDate = (state: any): string => (
  state.fluxAutomaticControlPanel.classicForecastDate
);

export const selectFluxAutomaticControlPanelClassicModel = (state: any): string => (
  state.fluxAutomaticControlPanel.classicModel
);

export const selectFluxAutomaticControlPanelClassicRevision = (state: any): string => (
  state.fluxAutomaticControlPanel.classicRevision
);

export const {
  setSpatialSubdivision,
  clearSpatialSubdivision,
  setAutomaticStudyScenarios,
  toggleAutomaticStudy,
  clearAutomaticStudyScenarios,
  setAutomaticStudy,
  setAutomaticStudyPreferenceScenarios,
  setAcomph,
  setMLT,
  togglePastSimulation,
  setPastSimulations,
  setMltOption,
  setRevisionDate,
  setClassicAcomphDate,
  setClassicForecastDate,
  setClassicModel,
  setClassicRevision,
  reset,
} = fluxAutomaticControlPanelSlice.actions;

export default fluxAutomaticControlPanelSlice.reducer;
