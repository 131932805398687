import { useGetHistoryQuery } from 'services/api/Meteorology/Weather/Area/History';
import GetHistoryParams from 'types/Meteorology/Weather/Area/getHistoryParams';

export default function useAreaHistory(params: GetHistoryParams) {
  const {
    data, error, isLoading, isFetching,
  } = useGetHistoryQuery(params);

  return {
    history: data, isLoading, isFetching, error,
  };
}
