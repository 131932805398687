import { useEffect } from 'react';
import { useLazyGetOverviewQuery } from 'services/api/Meteorology/Weather/Overview';
import { Overview } from 'types/Meteorology/Weather/Overview';

export default function useOverview(period: string): {
  overview: Overview | undefined, isLoading: boolean, isFetching: boolean, error: boolean
} {
  const [trigger, {
    data, error, isLoading, isFetching,
  }] = useLazyGetOverviewQuery();

  useEffect(() => {
    if (period !== '') trigger({ period });
  }, [period, trigger]);

  return {
    overview: data as Overview, isLoading, isFetching, error: !!error,
  };
}
