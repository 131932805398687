import { NavigateFunction } from 'react-router';
import { store } from 'redux/store';
import baseApi, { ResponseError } from '../services/api/base';
import handleAtuthorizationErrors from './tokenError';
import sendErrorToSentry from './sendErrorToSentry';
import resetControlPanels from './resetControlPanels';

/* eslint-disable import/prefer-default-export */
export const handleApiError = (
  navigate: NavigateFunction,
  error: ResponseError,
): void => {
  const hasAuthError = handleAtuthorizationErrors(error, navigate);
  if (hasAuthError) return;

  store.dispatch(baseApi.util.resetApiState());
  resetControlPanels();
  sendErrorToSentry(error);
  navigate(+error.status === 404 ? '/notfound' : '/error');
};
