// import { ResponseError } from 'services/api/base';
// import { useImpactReferenceQuery } from 'services/api/Meteorology/Weather/Area/References';
import ImpactReferenceParams from 'types/Meteorology/Weather/Area/getImpactReferenceParams';
// import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';

const basePdfUrl = 'https://meteorologia-clima.s3.us-east-1.amazonaws.com/oscilacoes/impactos-referencias/';

export default function useAreaReference(params: ImpactReferenceParams) {
  // const {
  //   data, error, isLoading, isFetching,
  // } = useImpactReferenceQuery(params);

  // useHandleRequestErrors(error as ResponseError);
  const pdfUrl = `${basePdfUrl}${params.area.toUpperCase()}.pdf`;
  const isLoading = false;

  // return { data, isLoading, isFetching };
  return { pdfUrl, isLoading };
}
