import { store } from 'redux/store';
import { reset as resetAutomaticFlux } from 'redux/reducers/fluxAutomaticControlPanelReducer';
import { reset as resetPersonalizedFlux } from 'redux/reducers/fluxPersonalizedFormReducer';
import { reset as resetUploadFlux } from 'redux/reducers/fluxUploadFormReducer';
import { reset as resetMeteorologyFieldsComparator } from 'redux/reducers/meteorologyFieldsComparatorControlPanelReducer';
import { reset as resetMeteorologyFields } from 'redux/reducers/meteorologyFieldsControlPanelReducer';
import { reset as resetMeteorologyFieldsObservedData } from 'redux/reducers/meteorologyFieldsObservedDataControlPanelReducer';
import { reset as resetSynopticCharts } from 'redux/reducers/synopticChartsReducer';

export default function resetControlPanels() {
  store.dispatch(resetAutomaticFlux());
  store.dispatch(resetPersonalizedFlux());
  store.dispatch(resetUploadFlux());
  store.dispatch(resetMeteorologyFieldsComparator());
  store.dispatch(resetMeteorologyFields());
  store.dispatch(resetMeteorologyFieldsObservedData());
  store.dispatch(resetSynopticCharts());
}
