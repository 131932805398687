import React from 'react';
import { WeatherMJOHistory } from 'types/Meteorology/Weather/Area/getHistoryResponse';
import useAreaHistory from 'hooks/Meteorology/Weather/Area/History/useAreaHistory';
import AlertsCollection from 'components/AlertsCollection';
import CentralizedSpinner from 'components/CentralizedSpinner';
import MJOHistoryCard from './Card';

export default function MJOHistory({
  areaAcronym,
}: {
  areaAcronym: string,
}) {
  const { history, isLoading, error } = useAreaHistory({ area: areaAcronym });
  const mjoHistory = history as WeatherMJOHistory | undefined;

  if (isLoading) {
    return <CentralizedSpinner />;
  }

  if (error) {
    return (
      <div className="mt-4">
        <AlertsCollection
          alerts={[{ title: '', message: 'Dados indisponíveis', variant: 'warning' }]}
        />
      </div>
    );
  }

  return (
    <MJOHistoryCard
      amplitudeCategories={mjoHistory?.amplitude.categoryData || []}
      amplitudeValues={mjoHistory?.amplitude.valueData || []}
      phaseCategories={mjoHistory?.fase.categoryData || []}
      phaseValues={mjoHistory?.fase.valueData || []}
    />
  );
}
